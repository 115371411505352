import { DataImportConfig } from "../../../../PrognosAI/models/dataImportConfig";

export type MissingMapping =
  | "timestamp"
  | "fromTimestamp"
  | "toTimestamp"
  | "time"
  | "fromTime"
  | "toTime"
  | "measurement"
  | "partitioner";

export default function useMappingsCheck(
  dataset: DataImportConfig | undefined
): MissingMapping[] {
  const missingMappings: MissingMapping[] = [];
  if (!dataset) {
    return [];
  }

  if (dataset.isScaled) {
    const timestampCols =
      dataset.dcicCsvColumns?.filter(
        (c) => c.columnType === "DateTime" || c.columnType === "Date"
      ) ?? [];
    if (timestampCols.length !== 1) {
      missingMappings.push("timestamp");
    }

    const hasDate = timestampCols.some((col) => col.columnType === "Date");
    const timeCols =
      dataset.dcicCsvColumns?.filter((c) => c.columnType === "Time") ?? [];
    if (hasDate && timeCols.length !== 1) {
      missingMappings.push("time");
    }
  } else {
    const fromCols =
      dataset.dcicCsvColumns?.filter(
        (c) =>
          c.isTsFrom && (c.columnType === "DateTime" || c.columnType === "Date")
      ) ?? [];
    if (fromCols.length !== 1) {
      missingMappings.push("fromTimestamp");
    }

    const toCols =
      dataset.dcicCsvColumns?.filter(
        (c) =>
          c.isTsTo && (c.columnType === "DateTime" || c.columnType === "Date")
      ) ?? [];
    if (toCols.length !== 1) {
      missingMappings.push("toTimestamp");
    }

    const hasFromDate = fromCols.some((col) => col.columnType === "Date");
    const fromTimeCols =
      dataset.dcicCsvColumns?.filter(
        (c) => c.isTsFrom && c.columnType === "Time"
      ) ?? [];
    if (hasFromDate && fromTimeCols.length !== 1) {
      missingMappings.push("fromTime");
    }

    const hasToDate = toCols.some((col) => col.columnType === "Date");
    const toTimeCols =
      dataset.dcicCsvColumns?.filter(
        (c) => c.isTsTo && c.columnType === "Time"
      ) ?? [];
    if (hasToDate && toTimeCols.length !== 1) {
      missingMappings.push("toTime");
    }
  }

  if (!dataset.dcicCsvColumns?.some((c) => c.columnType === "Numeric")) {
    missingMappings.push("measurement");
  }

  if (!dataset.dcicCsvColumns?.some((c) => c.columnType === "Categorical")) {
    missingMappings.push("partitioner");
  }

  return missingMappings;
}
