import React, { type JSX } from "react";
import { PlotParams } from "react-plotly.js";

import { LegendClickEvent } from "plotly.js";

import SkeletonChart from "../../../../components/skeletons/SkeletonChart";
import useSettings from "../../hooks/useSettings";
import {
  mapDecimalSepToChar,
  mapThousandsSepToChar,
} from "../formatter/Number";
import { DEFAULT_IMAGE_OPTIONS } from "./defaultImageOptions";

const LazyPlot = React.lazy(() => import("./LazyPlot"));

interface PrognosPlotProps extends PlotParams {
  id: string;
}

export default function PrognosPlot(allProps: PrognosPlotProps): JSX.Element {
  const { id, ...props } = allProps;

  const handleLegendClick = useLegendClickCallback(props);

  const separators = useSeparators();

  return (
    <React.Suspense
      fallback={
        <div className={props.className} style={props.style}>
          <div className="w-full h-full flex items-center justify-center animate-pulse">
            <SkeletonChart className="w-1/2 h-1/2" />
          </div>
        </div>
      }
    >
      <LazyPlot
        divId={id}
        {...props}
        onLegendClick={handleLegendClick}
        //useResizeHandler
        layout={{ separators, ...props.layout }}
        config={{
          ...props.config,
          toImageButtonOptions: {
            ...DEFAULT_IMAGE_OPTIONS,
            ...props.config?.toImageButtonOptions,
          },
          responsive: true,
          displaylogo: false,
        }}
      />
    </React.Suspense>
  );
}

function useSeparators(): string {
  const { decimalSeparator, thousandsSeparator } = useSettings();

  return (
    mapDecimalSepToChar(decimalSeparator) +
    mapThousandsSepToChar(thousandsSeparator)
  );
}

function useLegendClickCallback(
  props: PlotParams
): ((event: Readonly<LegendClickEvent>) => boolean) | undefined {
  const { onLegendClick, onLegendDoubleClick } = props;

  const timeoutRef = React.useRef<NodeJS.Timeout>(undefined);
  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  if (onLegendClick && onLegendDoubleClick) {
    return (e) => {
      if (e.event.detail === 1) {
        timeoutRef.current = setTimeout(() => {
          return onLegendClick(e);
        }, 150);
      }
      if (e.event.detail === 2) {
        clearTimeout(timeoutRef.current);
        return onLegendDoubleClick(e);
      }
      return false;
    };
  }

  return onLegendClick;
}
