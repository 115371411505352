import React, { type JSX, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router";

import { useQuery } from "@tanstack/react-query";

import {
  ArchiveBoxIcon,
  ArrowLeftStartOnRectangleIcon,
  PlusIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import {
  solutionUsedIdentifiersQuery,
  useCreateSolution,
} from "../../api/solutions";
import ButtonTabs, {
  TabDefinition,
} from "../../apps/PrognosAI/components/tabs/ButtonTabs";
import useSearchBoolean from "../../apps/PrognosAI/hooks/useSearchBoolean";
import Backlink from "../../components/Backlink";
import Form from "../../components/Form";
import Hero from "../../components/Hero";
import HeroActionButton from "../../components/HeroActionButton";
import HeroSubtitle from "../../components/HeroSubtitle";
import Slideover from "../../components/Slideover";
import Button from "../../components/buttons/Button";
import TextField from "../../components/form/TextField";
import TimezonePicker from "../../components/form/TimezonePicker";
import SuiteHelmet from "../../components/helmet/SuiteHelmet";
import { SolutionDraft } from "../../models/solution";
import {
  SolutionsPage,
  getOwnSolutionsPath,
  getSolutionsPath,
} from "../../routes/solutions";
import { getTasksPath } from "../../routes/tasks";
import { getUserSettingsPath } from "../../routes/userSettings";
import LogoutForm from "../Login/LogoutForm";
import { generateSolutionNameError } from "../SolutionSettings/SolutionSettings";

const DEFAULT_SOLUTION: SolutionDraft = {
  name: "",
  dataInterval: "Invalid",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  description: "",
  auditCreatedAt: new Date(),
  auditCreatedBy: null,
  auditChangedAt: null,
  auditChangedBy: null,
  phase: "New",
};

export default function Solutions(): JSX.Element {
  const [showArchived, setShowArchived] = useSearchBoolean("showArchived");
  const handleArchivedToggle = () => {
    setShowArchived(!showArchived);
  };

  const [newSolution, setNewSolution] =
    useState<SolutionDraft>(DEFAULT_SOLUTION);
  const [formOpen, setFormOpen] = useState(false);

  const { t } = useTranslation();

  const solutionNameRef = React.useRef<HTMLInputElement>(null);

  const [createSolution, isPending] = useCreateSolution();
  const handleSubmit = () => createSolution.mutate(newSolution);

  const { data: solutionUsedIdentifiers = [] } = useQuery(
    solutionUsedIdentifiersQuery()
  );

  const nameError = !isPending
    ? generateSolutionNameError(
        null,
        newSolution.name,
        solutionUsedIdentifiers,
        t
      )
    : undefined;

  const [tabs, selectedTab] = useSolutionsTabs();

  return (
    <>
      <SuiteHelmet title={t("Projects")} />
      <Hero
        omitHelmet
        actions={
          <>
            <LogoutForm id="projectsSignOut">
              {(btnProps) => (
                <HeroActionButton {...btnProps} title={t("Sign out")}>
                  <ArrowLeftStartOnRectangleIcon />
                </HeroActionButton>
              )}
            </LogoutForm>
            <Link to={getUserSettingsPath(undefined, undefined)}>
              <HeroActionButton
                id="userSettingsButton"
                title={t("User settings")}
              >
                <UserIcon />
              </HeroActionButton>
            </Link>
            <Link to={getTasksPath(undefined, undefined)}>
              <HeroActionButton
                id="systemTasksButton"
                title={t("System tasks")}
              >
                <RectangleStackIcon />
              </HeroActionButton>
            </Link>
            {!showArchived && (
              <HeroActionButton
                id="toggleArchived"
                onClick={handleArchivedToggle}
                title={t("Show archived projects")}
              >
                <ArchiveBoxIcon />
              </HeroActionButton>
            )}
            <HeroActionButton
              id="newSolutionButton"
              onClick={() => setFormOpen(true)}
              title={t("New project")}
              variant="primary"
            >
              <PlusIcon />
            </HeroActionButton>
          </>
        }
      >
        {showArchived ? t("Archived projects") : t("Projects")}
        {!!showArchived && (
          <HeroSubtitle>
            <Backlink id="backToActiveProjects" to={getSolutionsPath()}>
              {t("Back to active projects")}
            </Backlink>
          </HeroSubtitle>
        )}
      </Hero>
      <div className="space-y-4">
        <ButtonTabs tabs={tabs} selected={selectedTab} />
        <Outlet />
      </div>
      <Slideover
        title={t("New project")}
        open={formOpen}
        initialFocus={solutionNameRef}
        onClose={() => setFormOpen(false)}
      >
        <Form onSubmit={handleSubmit}>
          <TextField
            id="solution_name"
            ref={solutionNameRef}
            label={t("Name")}
            value={newSolution.name}
            onChange={(e) =>
              setNewSolution((prev) => ({ ...prev, name: e.target.value }))
            }
            error={nameError}
            supressErrorsBeforeEdit
          />
          <div>
            <TimezonePicker
              id="timezone"
              label={t("Time zone")}
              value={newSolution.timezone}
              onChange={(timezone) =>
                setNewSolution((prev) => ({
                  ...prev,
                  timezone,
                }))
              }
            />
          </div>
          <div className="mt-2 space-x-1 text-right">
            <Button
              id="saveNewSolutionButton"
              disabled={isPending || !!nameError}
              className={isPending ? "cursor-progress!" : ""}
              type="submit"
            >
              {t("Create project")}
            </Button>
          </div>
        </Form>
      </Slideover>
    </>
  );
}

function useSolutionsTabs(): [TabDefinition<SolutionsPage>[], SolutionsPage] {
  const { t } = useTranslation();

  const query = window.location.search;

  const tabs: TabDefinition<SolutionsPage>[] = [
    {
      key: "all",
      label: t("All projects"),
      href: `${getSolutionsPath()}${query}`,
    },
    {
      key: "own",
      label: t("My projects"),
      href: `${getOwnSolutionsPath()}${query}`,
    },
  ];

  const selectedTab =
    tabs.find((tab) =>
      window.location.pathname.match(new RegExp(`^/auth/${tab.key}`))
    )?.key ?? tabs[0].key;

  return [tabs, selectedTab];
}
