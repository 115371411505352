import { Config } from "plotly.js";

type Full<T> = {
  [P in keyof T]-?: T[P];
};

export const DEFAULT_IMAGE_OPTIONS: Full<
  Pick<Config["toImageButtonOptions"], "width" | "height" | "format" | "scale">
> = {
  width: 960,
  height: 540,
  format: "png",
  scale: 2,
};
