import React from "react";
import { useTranslation } from "react-i18next";

import { TFunction } from "i18next";

import { Aggregate } from "../models/aggregate";
import { Invalid } from "../models/primitives";
import ButtonGroup from "./buttons/ButtonGroup";
import FieldSkeleton from "./skeletons/FieldSkeleton";

interface LargeTimescaleButtonsProps {
  id: string;
  label?: string;
  options: Aggregate[];
  value: Aggregate | Invalid | null;
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (scale: Aggregate) => void;
  error?: string;
}

export default function LargeTimescaleButtons(
  props: LargeTimescaleButtonsProps
) {
  const { id, label, options, value, disabled, isLoading, error } = props;
  const { onChange } = props;

  const buttonOptions = useTimescaleOptions(options);

  if (isLoading) {
    return (
      <FieldSkeleton
        fieldId={id}
        fieldClassName="py-2 pl-3 pr-10"
        label={label}
      />
    );
  }

  const realValue = value !== "Invalid" ? value : null;

  return (
    <div className="w-full">
      {!!label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <ButtonGroup
        id={id}
        wrapperClassName={"w-full"}
        options={buttonOptions}
        active={realValue ?? undefined}
        disabled={disabled}
        onClick={onChange}
        error={error}
      />
    </div>
  );
}

export function useTimescaleOptions(
  scales: Aggregate[]
): { key: Aggregate; title: string }[] {
  const { t } = useTranslation();

  return scales.map((key) => ({ key, title: getScaleTitle(key, t) }));
}

function getScaleTitle(scale: Aggregate, t: TFunction): string {
  switch (scale) {
    case "1Y":
      return t("Time scale: 1 year");
    case "1Q":
      return t("Time scale: 1 year quarter");
    case "1M":
      return t("Time scale: 1 month");
    case "1W":
      return t("Time scale: 1 week");
    case "1D":
      return t("Time scale: 1 day");
    case "1h":
      return t("Time scale: 1 hour");
    case "30m":
      return t("Time scale: 30 minutes");
    case "15m":
      return t("Time scale: 15 minutes");
    case "10m":
      return t("Time scale: 10 minutes");
  }
}
