import React, { type JSX } from "react";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { FILTER_EL_STYLE } from ".";

type Option = { key: string; label?: string };

interface SelectProps {
  options: Option[];
  value: string;
  onChange(value: string): void;
}

export default function Select(props: SelectProps): JSX.Element {
  const { options, value, onChange } = props;

  const selectedOption: Option = options.find((op) => op.key === value) ?? {
    key: "",
  };

  return (
    <Listbox value={value} onChange={onChange}>
      <div
        className="relative inline-block grow h-[30px] mt-[1px]"
        onClick={(e) => e.stopPropagation()}
      >
        <ListboxButton
          className={
            "w-full relative cursor-pointer pl-2 pr-7 py-1 sm:text-sm " +
            FILTER_EL_STYLE
          }
        >
          <span className="block truncate">
            {selectedOption.label ?? selectedOption.key}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-3 w-3 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>
        <ListboxOptions className="w-full absolute z-10 mt-1 max-h-60 overflow-auto rounded-sm bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
          {options.map((option) => (
            <ListboxOption
              key={option.key}
              className={({ focus }) =>
                classNames(
                  focus ? "text-white bg-blue-600" : "text-gray-900",
                  "relative cursor-pointer select-none px-2 py-1"
                )
              }
              value={option.key}
            >
              {({ selected }) => (
                <>
                  <span
                    id={`filterSelect-option-${option.key}`}
                    className={classNames(
                      selected ? "font-semibold" : "font-normal",
                      "block truncate"
                    )}
                  >
                    {option.label ?? option.key}
                  </span>
                </>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
