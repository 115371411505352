import { z } from "zod";

import {
  DateTimeZod,
  DateZod,
  convertSnakeToCamelObject,
  convertToArray,
} from "../../../models/primitives";

export const taskNames = [
  "Statistics",
  "RunModel",
  "RunMultipleModels",
  "ModelAutoSelection",
  "DataCollectionImportAnalysis",
  "DataCollectionImport",
  "DataTableCreate",
  "DatasetInsert",
  "CalculateAggregates",
  "OutlierDetection",
  "ExportResult",
  "ExportIntradayProfiles",
  "ExportBusinessHours",
  "ExportInfluencingFactors",
  "ExportHistory",
  "ImportBusinessHours",
  "ImportInfluencingFactors",
  // internal tasks
  "UnitTest",
  "RunModelSubtask",
  // no longer created (still in db)
  "DataImportAnalysis",
  "DataImport",
] as const;
export const TaskNameZod = z.enum(taskNames);
export type TaskName = z.infer<typeof TaskNameZod>;

export const finalTaskStates = ["Finished", "Canceled", "Failed"] as const;
export const progressingTaskStates = [
  "Enqueued",
  "Blocked",
  "Started",
  "Canceling",
] as const;
export const taskStates = [
  ...progressingTaskStates,
  ...finalTaskStates,
] as const;
export const TaskStatusZod = z.enum(taskStates);
export type TaskStatus = z.infer<typeof TaskStatusZod>;

export const runningNonCanceledTasks: readonly TaskStatus[] = [
  TaskStatusZod.enum.Blocked,
  TaskStatusZod.enum.Enqueued,
  TaskStatusZod.enum.Started,
];

export const taskSubStates = [
  "Unset",
  "Success",
  "WithWarnings",
  "WithErrors",
  "AbortedBySystem",
  "Pending",
] as const;
export const TaskSubStatusZod = z.enum(taskSubStates);
export type TaskSubStatus = z.infer<typeof TaskSubStatusZod>;

export const taskErrorClasses = [
  "PythonError",
  "InternalException",
  "ModelRunConfigDoesNotExistError",
  "RunConfigDoesNotExistOrHasNoFixedModelsError",
  "NoModelDefinedError",
  "ParameterMissingError",
  "ParameterValueError",
  "ParameterTypeError",
  "TooFewDatapointsError",
  "NoDataError",
  "DateParameterOutsideHistoryError",
  "AllDataExcludedError",
  "NoDatapointsToForecastError",
  "PartitionHierarchyError",
  "ConfigurationError",
  "FileNotFoundError",
  "DataImportError",
  "EntityImportError",
  "UnexpectedDataTableFormatError",
  "AggregateForecastError",
  "SeasonalRegressionError",
  "ProphetError",
  "IntradayProfileError",
  "ArimaError",
  "PrognosException",
  // this is an artificial error only used in FE
  "DefaultError",
] as const;
export const TaskErrorClassZod = z.enum(taskErrorClasses).catch("DefaultError");
export type TaskErrorClass = z.infer<typeof TaskErrorClassZod>;

// prettier-multiline-arrays-next-line-pattern: 4
export const TaskErrorCodeZod = z
  .union([
    z.literal(1),
    z.literal(2),
    z.literal(11),
    z.literal(12),
    z.literal(13),
    z.literal(100),
    z.literal(101),
    z.literal(102),
    z.literal(103),
    z.literal(104),
    z.literal(105),
    z.literal(106),
    z.literal(110),
    z.literal(111),
    z.literal(200),
    z.literal(209),
    // DataImportError
    z.literal(210),
    z.literal(211),
    z.literal(212),
    z.literal(213),
    z.literal(214),
    z.literal(215),
    z.literal(216),
    z.literal(217),
    z.literal(218),
    z.literal(219),
    z.literal(220),
    z.literal(221),
    z.literal(222),
    z.literal(223),
    z.literal(224),
    z.literal(225),
    z.literal(226),
    z.literal(227),
    z.literal(228),
    // EntityImportError
    z.literal(240),
    z.literal(241),
    z.literal(242),
    z.literal(243),
    z.literal(244),
    z.literal(245),
    z.literal(246),
    z.literal(247),
    z.literal(248),
    z.literal(249),
    z.literal(250),
    z.literal(251),
    z.literal(252),
    z.literal(253),
    z.literal(254),
    z.literal(255),
    z.literal(256),
    z.literal(257),
    z.literal(258),
    z.literal(259),
    z.literal(260),
    z.literal(261),
    z.literal(262),
    z.literal(263),
    z.literal(264),
    // UnexpectedDataTableFormatError
    z.literal(265),
    // EntityImportError
    z.literal(266),
    z.literal(267),
    z.literal(268),
    z.literal(270),
    z.literal(271),
    z.literal(301),
    z.literal(310),
    z.literal(320),
    z.literal(321),
    z.literal(322),
    z.literal(326),
    // PrognosException
    z.literal(1000),
    z.literal(1004),
    z.literal(1005),
    z.literal(1006),
    z.literal(1007),
    z.literal(1008),
    z.literal(1009),
    z.literal(999), // this is an artificial code only used in FE
  ])
  .catch(999);
export type TaskErrorCode = z.infer<typeof TaskErrorCodeZod>;

// what David calls frequency in his errors
const FrequencyZod = z
  .string()
  .transform((arg) => (!arg.match(/^\d/) ? `1${arg}` : arg));

const ExampleLineZod = z.object({
  line: z.number(),
  value: z.string(),
});
export type ExampleLineZod = z.infer<typeof ExampleLineZod>;

export const TaskProblemPropsZod = z
  .object({
    parameterName: z.string(),
    valueWas: z.any(),
    valueShould: z.any(),
    typeWas: z.string(),
    typeShould: z.string(),
    numDatapointsIs: convertToArray(z.number().array()),
    minNumDatapoints: z.number(),
    aggregateLevel: z.string(),
    maxAutoregressiveLag: z.number(),
    historicDataLength: z.number(),
    measurementName: z.string(),
    newUnit: z.string(),
    savedUnit: z.string(),
    inferredFrequency: FrequencyZod,
    existingFrequency: FrequencyZod,
    selectedFrequency: FrequencyZod,
    minAllowedFrequency: FrequencyZod,
    partitions: z.string().array(),
    algorithmConfigId: z.number().nullable().optional(),
    incompatibleTimestamps: z.string().array().optional(),
    valSplit: z.number(),
    valSplitDate: DateZod.nullable(),
    backtestStart: z.number(),
    backtestStartDate: DateZod.nullable(),
    nrRoundedTimestamps: z.number(),
    exampleTsBeforeRounding: DateTimeZod,
    exampleTsAfterRounding: DateTimeZod,
    exampleTimestamp: z.string(), // has to be a string because it might be invalid
    referencePeriodStart: DateZod,
    referencePeriodEnd: DateZod,
    missingMonths: z.number().array(),
    missingQuarters: z.number().array(),
    lineNumbers: z.number().array().nullable(),
    examples: ExampleLineZod.array().nullable(),
    selectedSeasonality: z.string(),
    minimumSeasonality: z.string(),
    maximumSeasonality: z.string(),
    causedBy: z.string(),
    // EntityImportError
    fileType: z.string(),
    columnSeparator: z.string().optional().catch(undefined),
    rawLine: z.string(),
    missingColumns: z.string().array(),
    example: z.string().optional().catch(undefined),
    expectedFormat: z.string(),
    invalidShape: z.string(),
    invalidWeight: z.string(),
    dateFrom: z.string(),
    dateTo: z.string(),
    factorName: z.string(),
    shapes: z.string().array(),
    invalidEntry: z.string(),
    timeFrom: z.string(),
    timeTo: z.string(),
    invalidPartitions: z.string().array().array(),
    requiredColumns: z.string().array(),
    invalidTimescale: z.string(),
    validTimescales: z.string().array(),
    invalidTimeRangeLength: z.string().or(z.number()),
    timeScales: z.string().array(),
    timeRangeLengths: z.union([z.number(), z.string()]).array(),
    numFiles: z.number(),
    invalidRrule: z.string(),
    value: z.string(),
    reason: z.string(),
    // it's really a typo in the API - "seperator"
    seperator: z.enum(["decimal", "thousands"]).nullable().catch(null),
    column: z.string().nullable(),
    areaCount: z.number(),
    // FallbackAlgorithmUsedWarning
    errorName: z.string(),
    errorCode: z.number(),
    errorProperties: z.unknown(),
    errorTraceback: z.string(),
    modelId: z.number(),
    // PrognosException
    offender: z.string(),
  })
  .partial();
export type TaskProblemProps = z.infer<typeof TaskProblemPropsZod>;

export const taskWarningClasses = [
  "SummaryStatisticsFailedWarning",
  "ModelEvaluationFailedWarning",
  "FixedModelHasBeenDeletedWarning",
  "IsolatedValuesRemovedWarning",
  "AdditionalInterpolationWarning",
  "DataFromReferencePlanningAreaUsedWarning",
  "FallbackAlgorithmUsedWarning",
  "PartitionHierarchyWarning",
  "SeasonalityIgnoredWarning",
  "BoxCoxTransformationFailedWarning",
  "DataImportWarning",
  "PrognosException",
  // this is an artificial warning only used in FE
  "DefaultWarning",
] as const;
export const TaskWarningClassZod = z
  .enum(taskWarningClasses)
  .catch("DefaultWarning");
export type TaskWarningClass = z.infer<typeof TaskWarningClassZod>;

// prettier-multiline-arrays-next-line-pattern: 4
export const TaskWarningCodeZod = z
  .union([
    z.literal(4),
    z.literal(5),
    z.literal(14),
    z.literal(106),
    z.literal(107),
    z.literal(108),
    z.literal(109),
    z.literal(110),
    z.literal(120),
    z.literal(122),
    z.literal(225),
    z.literal(1002),
    z.literal(1003),
    z.literal(999), // this is an artificial code only used in FE
  ])
  .catch(999);
export type TaskWarningCode = z.infer<typeof TaskWarningCodeZod>;

const TaskProblemPropsTransformed = convertSnakeToCamelObject(
  TaskProblemPropsZod
)
  .or(convertSnakeToCamelObject(TaskProblemPropsZod).array())
  .catch({})
  .transform((arg) => (Array.isArray(arg) ? (arg.at(0) ?? {}) : arg));

export const TaskProblemZod = z.object({
  properties: TaskProblemPropsTransformed,
  partitionId: z.number().array().catch([]),
  measurementId: z.number().array().catch([]),
  msg: z.string().nullable(),
  trace: z.string().nullable().optional(),
});
export type TaskProblem = z.infer<typeof TaskProblemZod>;

export const TaskZod = z.object({
  taskId: z.string(),
  solutionId: z.number(),
  taskName: TaskNameZod,
  status: TaskStatusZod,
  subStatus: TaskSubStatusZod.nullable().catch(null),
  enqueuedTime: DateTimeZod.nullable().optional(),
  startedTime: DateTimeZod.nullable().optional(),
  finishedTime: DateTimeZod.nullable().optional(),
  progress: z.number().optional(),
  solution: z.object({ name: z.string() }).nullable().optional(),
});
export type Task = z.infer<typeof TaskZod>;

export const DatasetTaskZod = TaskZod.extend({
  dataCollectionImportConfigId: z.number(),
});
export type DatasetTask = z.infer<typeof DatasetTaskZod>;

export const GeneralSolutionTaskZod = TaskZod.extend({
  solutionId: z.number(),
  importExportConfigId: z.number().nullable().optional(),
});
export type GeneralSolutionTask = z.infer<typeof GeneralSolutionTaskZod>;

export const ModelRunTaskZod = GeneralSolutionTaskZod.extend({
  modelIds: z.number().array().catch([]),
  runConfigId: z.number(),
  modelRunConfigId: z.number().nullable().optional(),
});
export type ModelRunTask = z.infer<typeof ModelRunTaskZod>;

export const ChartTaskZod = GeneralSolutionTaskZod.extend({
  chartId: z.number(),
});
export type ChartTask = z.infer<typeof ChartTaskZod>;

export const diagnosticTypes = ["Error", "Warning"] as const;
export const DiagnosticTypeZod = z.enum(diagnosticTypes);
export type DiagnosticType = z.infer<typeof DiagnosticTypeZod>;

export const TaskDiagnosticBetaZod = z.object({
  taskDiagnosticId: z.number(),
  taskId: z.string(),
  msg: z.string(),
  trace: z.string().nullable().optional(),
  properties: TaskProblemPropsTransformed,
});
export const TaskDiagnosticZod = z.discriminatedUnion("diagnosticType", [
  TaskDiagnosticBetaZod.extend({
    diagnosticType: z.literal("Error"),
    code: TaskErrorCodeZod,
    name: TaskErrorClassZod,
  }),
  TaskDiagnosticBetaZod.extend({
    diagnosticType: z.literal("Warning"),
    code: TaskWarningCodeZod,
    name: TaskWarningClassZod,
  }),
]);
export type TaskDiagnostic = z.infer<typeof TaskDiagnosticZod>;

export const TaskDetailsZod = TaskZod.extend({
  modelRunConfigId: z.number().nullable().optional(),
  modelId: z.number().nullable().optional(),
  runConfigId: z.number().nullable().optional(),
  chartId: z.number().nullable().optional(),
  dataCollectionImportConfigId: z.number().nullable().optional(),
  runResultId: z.number().nullable().optional(),
  importExportConfigId: z.number().nullable().optional(),
  diagnostics: TaskDiagnosticZod.array(),
});
export type TaskDetails = z.infer<typeof TaskDetailsZod>;
