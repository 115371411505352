import { z } from "zod";

import { AggregateZod } from "../../../models/aggregate";
import { DateTimeZod } from "../../../models/primitives";
import { GeneralImportModeZod, ImportExportFileTypeZod } from "./file";
import { AggregationMethodZod } from "./measurement";
import { DecimalSeparatorZod, ThousandsSeparatorZod } from "./settings";
import { TaskZod } from "./task";

export const dataImportModes = [
  GeneralImportModeZod.Enum.Replace,
  "SoftReplace",
  GeneralImportModeZod.Enum.Overwrite,
  GeneralImportModeZod.Enum.Append,
] as const;
export const DataImportModeZod = z.enum(dataImportModes);
export type DataImportMode = z.infer<typeof DataImportModeZod>;

export const PreviewRowZod = z
  .union([z.string(), z.number(), z.null()])
  .array();
export type PreviewRow = z.infer<typeof PreviewRowZod>;

export const importColumnTypes = [
  "Categorical",
  "Numeric",
  "DateTime",
  "Date",
  "Time",
] as const;
export const ImportColumnTypeZod = z.enum(importColumnTypes);
export type ImportColumnType = z.infer<typeof ImportColumnTypeZod>;

export const ImportConfigColumnZod = z.object({
  dcicCsvColumnId: z.number(),
  dcicId: z.number(),
  csvName: z.string(),
  displayName: z.string().nullable().optional(),
  inferredDate: z.boolean().optional(),
  inferredTime: z.boolean().optional(),
  inferredDateTime: z.boolean().optional(),
  inferredObservable: z.boolean().optional(),
  inferredCategorical: z.boolean().optional(),
  columnType: ImportColumnTypeZod.nullable(),
  thousandsSeparator: ThousandsSeparatorZod.nullable(),
  decimalSeparator: DecimalSeparatorZod.nullable(),
  isTsFrom: z.boolean().nullable(),
  isTsTo: z.boolean().nullable(),
  dateTimeFormat: z.string().nullable(),
  categorical: z.unknown().nullable(),
  hierarchyLevel: z.number().nullable(),
  observable: z.unknown().nullable(),
  unit: z.string().nullable(),
  aggregationMethod: AggregationMethodZod,
});
export type ImportConfigColumn = z.infer<typeof ImportConfigColumnZod>;

const ImportMetadataZod = z.object({
  measurementIds: z.number().array(),
  partitionIds: z.number().array(),
  leafPartitionIds: z.number().array(),
});

export const DataImportConfigZod = z.object({
  dataCollectionImportConfigId: z.number(),
  dataCollectionId: z.number(),
  importMode: DataImportModeZod,
  analyzedTimestamp: DateTimeZod.nullable().optional(),
  importTimestamp: DateTimeZod.nullable().optional(),
  fileType: ImportExportFileTypeZod.nullable().optional(),
  isScaled: z.boolean().catch(false),
  timezone: z.string(),
  responsibleTask: TaskZod.omit({ solution: true, solutionId: true })
    .nullable()
    .optional(),
  // after successful analysis:
  uploadedFileName: z.string().nullable(),
  uncompressedFileName: z.string().nullable(),
  isAnalyzed: z.boolean().default(false),
  fileSize: z.number().nullable(),
  head: PreviewRowZod.array().catch([]),
  tail: PreviewRowZod.array().catch([]),
  isOrdered: z.boolean().nullable(),
  isDuplicateFree: z.boolean().nullable(),
  dcicCsvColumns: ImportConfigColumnZod.array().nullable(),
  dataInterval: AggregateZod.or(z.literal("Invalid")).nullable().optional(),
  // after successful import:
  nrRows: z.number().nullable().optional(),
  firstTimestamp: DateTimeZod.nullable().optional(),
  lastTimestamp: DateTimeZod.nullable().optional(),
  oldMetadata: ImportMetadataZod.nullable(),
  currentMetadata: ImportMetadataZod.nullable(),
  newMetadata: ImportMetadataZod.nullable(),
  measurementIdsInFile: z.number().array().nullable().optional(),
  partitionIdsInFile: z.number().array().nullable().optional(),
  newMeasurementIds: z.number().array().nullable().optional(),
  newPartitionIds: z.number().array().nullable().optional(),
  nrLeafPartitionsInFile: z.number().nullable().optional(),
  nrMeasurementsInFile: z.number().nullable().optional(),
  nrNewAggregatePartitions: z.number().nullable().optional(),
  nrNewLeafPartitions: z.number().nullable().optional(),
  nrNewMeasurements: z.number().nullable().optional(),
  nrRemovedAggregatePartitions: z.number().nullable().optional(),
  nrRemovedLeafPartitions: z.number().nullable().optional(),
  nrRemovedMeasurements: z.number().nullable().optional(),
});
export type DataImportConfig = z.infer<typeof DataImportConfigZod>;

export const importExportZones = ["Utc", "Local"] as const;
export const ImportExportZoneZod = z.enum(importExportZones);
export type ImportExportZone = z.infer<typeof ImportExportZoneZod>;
