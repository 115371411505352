import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

import api, { getQueryString } from "../../../api";
import { EndpointOptions } from "../../../api";
import {
  DataImportConfig,
  DataImportConfigZod,
  ImportConfigColumn,
  ImportConfigColumnZod,
} from "../../PrognosAI/models/dataImportConfig";
import {
  DataImportConfigDependency,
  DataImportConfigErrorZod,
} from "../../PrognosAI/models/dependency";
import {
  PaginatedResponse,
  paginatedResponse,
} from "../../PrognosAI/models/response";
import {
  CompleteUpload,
  UploadLinkContainer,
  UploadLinkContainerZod,
} from "../../PrognosAI/models/upload";
import { getImportDetailPath, getImportPath } from "../routes/import";

const DATA_IMPORT_API = "/Datastore/DataCollectionImportConfigs";

async function getSolutionDataImportConfigs(
  solutionId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<DataImportConfig[]>> {
  const query = getQueryString(options);
  return paginatedResponse(DataImportConfigZod.array()).parse(
    (
      await api.get(
        `/Datastore/Solutions/${solutionId}/DataCollectionImportConfigs?${query}`
      )
    ).data
  );
  // try {
  //   return paginatedResponse(DataImportConfigZod.array()).parse(
  //     (
  //       await api.get(
  //         `/Datastore/Solutions/${solutionId}/DataCollectionImportConfigs?${query}`
  //       )
  //     ).data
  //   );
  // } catch (e) {
  //   console.error(e);
  //   throw e;
  // }
}

export const solutionDataImportConfigsQuery = (
  solutionId: string | number,
  options?: EndpointOptions
) => ({
  queryKey: [
    "solutionDataImportConfigs",
    solutionId,
    ...(options ? [options] : []),
  ],
  queryFn: () => getSolutionDataImportConfigs(solutionId, options),
});

async function getDataImportConfig(
  dataImportConfigId: string | number
): Promise<DataImportConfig> {
  return DataImportConfigZod.parse(
    (await api.get(`${DATA_IMPORT_API}/${dataImportConfigId}`)).data
  );
}

export const dataImportConfigQuery = (dataImportConfigId: string | number) => ({
  queryKey: ["dataImportConfig", dataImportConfigId.toString()],
  queryFn: async () => await getDataImportConfig(dataImportConfigId),
});

async function createDataImportConfig(
  dataCollectionId: string | number,
  dataImportConfig: DataImportConfig
): Promise<DataImportConfig> {
  return DataImportConfigZod.parse(
    (
      await api.post(
        `/Datastore/DataCollections/${dataCollectionId}/DataCollectionImportConfigs`,
        dataImportConfig
      )
    ).data
  );
}

export const useCreateDataImportConfig = (
  solutionId: string | number,
  dataCollectionId: string | number,
  noRedirect = false,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (dataImportConfig: DataImportConfig) =>
      createDataImportConfig(dataCollectionId, dataImportConfig),
    onSuccess: ({ dataCollectionImportConfigId }) => {
      if (!silent) {
        toast.success(t("Import configuration saved successfully."));
      }
      queryClient.invalidateQueries(solutionDataImportConfigsQuery(solutionId));
      if (!noRedirect) {
        navigate(
          getImportDetailPath(dataCollectionId, dataCollectionImportConfigId)
        );
      }
    },
    onError: () => {
      if (!silent) {
        toast.error(t("An error occurred while saving. Please try again."));
      }
    },
  });
};

async function updateDataImportConfig(
  dataImportConfigId: string,
  patch: Partial<DataImportConfig>
) {
  return DataImportConfigZod.parse(
    (await api.patch(`${DATA_IMPORT_API}/${dataImportConfigId}`, patch)).data
  );
}

export const useEditDataImportConfig = (
  solutionId: string,
  dataImportConfigId: string
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<DataImportConfig>) =>
      updateDataImportConfig(dataImportConfigId, patch),
    onSuccess: (newDataImportConfig) => {
      queryClient.setQueryData(
        dataImportConfigQuery(dataImportConfigId).queryKey,
        newDataImportConfig
      );
      queryClient.invalidateQueries(solutionDataImportConfigsQuery(solutionId));
    },
    onError: () => {
      toast.error(
        t(
          "The changes were not saved. Please wait or refresh the page to start over."
        )
      );
    },
  });
};

async function updateImportConfigColumn(
  dcicCsvColumnId: string | number,
  patch: Partial<ImportConfigColumn>
) {
  return ImportConfigColumnZod.parse(
    (await api.patch(`/Datastore/DcicCsvColumns/${dcicCsvColumnId}`, patch))
      .data
  );
}

function setImportConfigCache(
  queryClient: QueryClient,
  dataImportConfigId: string | number,
  newImportColumn: ImportConfigColumn
) {
  queryClient.setQueryData<DataImportConfig>(
    dataImportConfigQuery(dataImportConfigId).queryKey,
    (prev) => {
      if (!prev) {
        return prev;
      }

      const newConfig: DataImportConfig = { ...prev };
      newConfig.dcicCsvColumns =
        newConfig.dcicCsvColumns?.map((col) =>
          col.dcicCsvColumnId === newImportColumn.dcicCsvColumnId
            ? newImportColumn
            : col
        ) ?? null;

      return newConfig;
    }
  );
}

export const useUpdateImportConfigColumn = (
  dataCollectionImportConfigId: string | number
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      dcicCsvColumnId,
      patch,
    }: {
      dcicCsvColumnId: string | number;
      patch: Partial<ImportConfigColumn>;
    }) => updateImportConfigColumn(dcicCsvColumnId, patch),
    onSuccess: (newImportColumn) => {
      setImportConfigCache(
        queryClient,
        dataCollectionImportConfigId,
        newImportColumn
      );
    },
    onError: () => {
      toast.error(t("The changes were not saved. Please try again."));
    },
  });
};

export const useUpdateStandaloneImportConfigColumn = (
  dataCollectionImportConfigId: string | number,
  dcicCsvColumnId: string | number
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<ImportConfigColumn>) =>
      updateImportConfigColumn(dcicCsvColumnId, patch),
    onSuccess: (newImportColumn) => {
      setImportConfigCache(
        queryClient,
        dataCollectionImportConfigId,
        newImportColumn
      );
    },
    onError: () => {
      toast.error(
        t(
          "The changes were not saved. Please wait or refresh the page to start over."
        )
      );
    },
  });
};

async function deleteDataImportConfig(dataImportConfigId: number | string) {
  return api.delete(`${DATA_IMPORT_API}/${dataImportConfigId}`);
}

export const useDeleteDataImportConfig = (
  solutionId: string,
  redirect = false,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (dataImportConfigId: number | string) =>
      deleteDataImportConfig(dataImportConfigId),
    onSuccess: (_, dataImportConfigId) => {
      if (!silent) {
        toast.success(t("Import configuration deleted successfully."));
      }
      queryClient.invalidateQueries(solutionDataImportConfigsQuery(solutionId));
      queryClient.removeQueries(dataImportConfigQuery(dataImportConfigId));
      if (redirect) {
        navigate(getImportPath(solutionId));
      }
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteDataImportConfigsByIds(ids: number[]) {
  return api.delete(`${DATA_IMPORT_API}/Batch`, { data: { ids } });
}

export const useDeleteDataImportConfigsByIds = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteDataImportConfigsByIds,
    onSuccess: (_, ids) => {
      toast.success(t("Import configurations deleted successfully."));
      queryClient.invalidateQueries(solutionDataImportConfigsQuery(solutionId));
      queryClient.removeQueries(...ids.map((id) => dataImportConfigQuery(id)));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteDataImportConfigsByFilter(
  solutionId: string | number,
  options: EndpointOptions
) {
  const query = getQueryString(options);
  return api.delete(
    `/Datastore/DataCollections/${solutionId}/DataCollectionImportConfigs?${query}`
  );
}

export const useDeleteDataImportConfigsByFilter = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (options: EndpointOptions) =>
      deleteDataImportConfigsByFilter(solutionId, options),
    onSuccess: () => {
      toast.success(t("Import configurations deleted successfully."));
      queryClient.invalidateQueries(solutionDataImportConfigsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

export async function getDataImportConfigUploadContainer(
  dataImportConfigId: number | string,
  fileName: string,
  fileSize: number
): Promise<UploadLinkContainer> {
  return UploadLinkContainerZod.parse(
    (
      await api.get(
        `${DATA_IMPORT_API}/${dataImportConfigId}/UploadLink?fileName=${fileName}&fileSize=${fileSize}`
      )
    ).data
  );
}

export async function completeDataImportConfigUpload(
  dataImportConfigId: number | string,
  data: CompleteUpload
) {
  return api.post(
    `${DATA_IMPORT_API}/${dataImportConfigId}/CompleteUpload`,
    data
  );
}

export async function getDataImportConfigDownloadLink(
  dataImportConfigId: string | number
): Promise<string> {
  const response = z
    .object({ link: z.string() })
    .parse(
      (await api.get(`${DATA_IMPORT_API}/${dataImportConfigId}/DownloadLink`))
        .data
    );
  return response.link;
}

export const dataImportConfigDownloadLinkQuery = (
  dataImportConfigId: string
) => ({
  queryKey: ["dataImportConfigDownloadLink", dataImportConfigId],
  queryFn: async () =>
    await getDataImportConfigDownloadLink(dataImportConfigId),
});

async function startDataImportConfigAnalysis(
  dataImportConfigId: string | number
) {
  return api.post(`${DATA_IMPORT_API}/${dataImportConfigId}/Analyze`);
}

export const useStartDataImportConfigAnalysis = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (dataImportConfigId: string | number) =>
      startDataImportConfigAnalysis(dataImportConfigId),
    onSuccess: (_, dataImportConfigId) => {
      queryClient.invalidateQueries(dataImportConfigQuery(dataImportConfigId));
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function startDataImportConfigImport(
  dataImportConfigId: number | string,
  force = false
) {
  return api.post(
    `${DATA_IMPORT_API}/${dataImportConfigId}/Import${force ? "?force_on_replace=True" : ""}`
  );
}

export const useStartDataImportConfigImport = (
  dataImportConfigId: string | number,
  onConfirmation: (deps: DataImportConfigDependency[]) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ force = false }: { force?: boolean } = {}) =>
      startDataImportConfigImport(dataImportConfigId, force),
    onSuccess: () => {
      queryClient.invalidateQueries(dataImportConfigQuery(dataImportConfigId));
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 424) {
        try {
          console.log(error.response.data);
          const response = DataImportConfigErrorZod.parse(error.response.data);
          return onConfirmation(response.values);
        } catch (error) {
          console.error(error);
        }
      }
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

type HierarchyPatch = { hierarchy: number[] };

async function updateDataImportHierarchy(
  dataImportConfigId: string | number,
  patch: HierarchyPatch
) {
  return DataImportConfigZod.parse(
    (
      await api.patch(
        `Datastore/DataCollectionImportConfigs/${dataImportConfigId}/Hierarchy`,
        patch
      )
    ).data
  );
}

export const useUpdateDataImportHierarchy = (
  dataImportConfigId: string | number
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: HierarchyPatch) =>
      updateDataImportHierarchy(dataImportConfigId, patch),
    onSuccess: (dataImportConfig) => {
      queryClient.setQueryData<DataImportConfig>(
        dataImportConfigQuery(dataImportConfigId).queryKey,
        dataImportConfig
      );
    },
    onError: () => {
      toast.error(t("The changes were not saved. Please try again."));
    },
  });
};
