import React, { type JSX } from "react";

import Apps from "../../components/Apps";
import HeroBreadcrumbs from "../../components/HeroBreadcrumbs";

import logo from "../../../public/images/logo.svg";

export default function AppsOverview(): JSX.Element {
  return (
    <>
      <HeroBreadcrumbs path={[]} />
      <div className="p-4 w-full grow flex flex-col items-center justify-center gap-16">
        <img className="h-16 w-auto" src={logo} alt="TIS Prognos AI" />
        <Apps
          id="overviewApps"
          size="large"
          className="w-full flex flex-wrap justify-center gap-8"
          omitApps={["Home"]}
        />
      </div>
    </>
  );
}
