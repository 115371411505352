import { PlotData } from "plotly.js";
import { z } from "zod";

import { DateTimeZod } from "../../../models/primitives";

export const seriesTypes = [
  "Line",
  "Area",
  "Bar",
  "Scatter",
  "Step",
  "Interval",
] as const;
export const SeriesTypeZod = z.enum(seriesTypes);
export type SeriesType = z.infer<typeof SeriesTypeZod>;

// change this only if the other things are changed (e.g. extra lines calculation)
// src\apps\PrognosAI\pages\Result\classes\ResultSeriesFactory.ts - getStepLinePaths
// src\apps\PrognosAI\pages\Result\hooks\useChartElements.test.ts
export const STEP_MODE: PlotData["line"]["shape"] = "hv";

export const lineTypes = ["Solid", "Dash", "Dashdot", "Dot"] as const;
const LineTypeZod = z.enum(lineTypes);
export type LineType = z.infer<typeof LineTypeZod>;

export const SeriesPointZod = z.object({
  x: DateTimeZod,
  y: z.number().or(z.null()),
});
export type SeriesPoint = z.infer<typeof SeriesPointZod>;

export const ChartSeriesZod = z.object({
  chartSeriesId: z.number().or(z.string()),
  measurementId: z.number(),
  partitionId: z.number(),
  name: z.string(),
  seriesType: SeriesTypeZod,
  color: z.string(),
  lineType: LineTypeZod.optional(),
  data: SeriesPointZod.array().optional(),
  unit: z.string().optional(),
  hidden: z.boolean(),
  yAxis: z.string().optional(),
});
export type ChartSeries = z.infer<typeof ChartSeriesZod>;

export const DataSegmentMinMaxZod = z.object({
  partitionId: z.number(),
  measurementId: z.number(),
  minDate: DateTimeZod,
  maxDate: DateTimeZod,
});

export type DataSegmentMinMax = z.infer<typeof DataSegmentMinMaxZod>;
