import React from "react";

import classNames from "classnames";

interface RadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  variant?: "primary" | "secondary";
}

export default function Radio(props: RadioProps) {
  const { variant = "primary", className = "", ...rest } = props;

  return (
    <input
      {...rest}
      type="radio"
      className={classNames(
        "relative size-4 appearance-none rounded-full border border-gray-300 bg-white",
        "before:absolute before:inset-1 before:rounded-full before:bg-white",
        "",
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        "forced-colors:appearance-auto forced-colors:before:hidden not-checked:before:hidden",
        variant === "primary"
          ? "checked:border-blue-600 checked:bg-blue-600 focus-visible:outline-blue-600"
          : "checked:border-gray-400 checked:bg-gray-400 focus-visible:outline-gray-300",
        "cursor-pointer disabled:cursor-default",
        "disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 disabled:checked:bg-gray-300",
        className
      )}
    />
  );
}
