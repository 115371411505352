import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { ModelRunDependency, RunDeletionErrorZod } from "../models/dependency";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  ResultPreview,
  ResultPreviewZod,
  Run,
  RunDetail,
  RunDetailZod,
  RunUsedIdentifiers,
  RunUsedIdentifiersZod,
  RunZod,
} from "../models/run";
import {
  RunConfigPage,
  getRunDetailPath,
  getRunsPath,
} from "../routes/runConfigs";
import { getFactorsQueryPrefix } from "./influencingFactors";
import { runFixedModelsQuery, runPartitionsQuery } from "./runFixedModels";

export const RUNS_API = "/Prognos/RunConfigs";

async function getRuns(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<Run[]>> {
  const query = getQueryString(options);
  const runsQ = paginatedResponse(RunZod.array()).parse(
    (await api.get(`/Prognos/Solutions/${solutionId}/RunConfigs?${query}`)).data
  );
  return runsQ;
}

function getRunsQueryPrefix(solutionId: string) {
  return { queryKey: ["runs", solutionId] };
}

export const runsQuery = (solutionId: string, options?: EndpointOptions) => ({
  queryKey: [
    ...getRunsQueryPrefix(solutionId).queryKey,
    "list",
    ...(options ? [options] : []),
  ],
  queryFn: () => getRuns(solutionId, options),
});

async function getRun(runConfigId: string | number): Promise<RunDetail> {
  return RunDetailZod.parse((await api.get(`${RUNS_API}/${runConfigId}`)).data);
}

export const runQuery = (runConfigId: string | number | undefined) => ({
  queryKey: ["run", runConfigId?.toString()],
  queryFn: () => getRun(runConfigId ?? ""),
  enabled: !!runConfigId,
});

async function createRun(
  solutionId: string,
  run: RunDetail
): Promise<RunDetail> {
  return RunDetailZod.parse(
    (await api.post(`/Prognos/Solutions/${solutionId}/RunConfigs`, run)).data
  );
}

export const useCreateRun = (
  solutionId: string,
  section: RunConfigPage | "" = ""
): [UseMutationResult<RunDetail, Error, RunDetail>, boolean] => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isPending, setIsPending] = React.useState(false);
  const mutation = useMutation({
    mutationFn: (run: RunDetail) => createRun(solutionId, run),
    onMutate: () => setIsPending(true),
    onSuccess: (data) => {
      toast.success(t("Run saved successfully."));
      queryClient.invalidateQueries(getRunsQueryPrefix(solutionId));
      navigate(`${getRunDetailPath(solutionId, data.runConfigId)}/${section}`);
    },
    onError: () => {
      setIsPending(false);
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });

  return [mutation, isPending];
};

async function updateRun(runConfigId: string, patch: Partial<RunDetail>) {
  return RunDetailZod.parse(
    (await api.patch(`${RUNS_API}/${runConfigId}`, patch)).data
  );
}

export const useEditRun = (
  solutionId: string,
  runConfigId: string,
  retriesOnError = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<RunDetail>) => updateRun(runConfigId, patch),
    onSuccess: (run) => {
      queryClient.setQueryData(runQuery(runConfigId).queryKey, run);
      queryClient.invalidateQueries({
        queryKey: ["resultPreview", runConfigId],
      });
      queryClient.invalidateQueries(getRunsQueryPrefix(solutionId));
      queryClient.invalidateQueries(runFixedModelsQuery(runConfigId));
      queryClient.invalidateQueries(runPartitionsQuery(runConfigId));
    },
    onError: () => {
      toast.error(
        retriesOnError
          ? t(
              "The changes were not saved. Please wait or refresh the page to start over."
            )
          : t("The changes were not saved. Please try again.")
      );
    },
  });
};

async function copyRun(runConfigId: string | number) {
  return RunDetailZod.parse(
    (await api.post(`${RUNS_API}/${runConfigId}/Copy`)).data
  );
}

export const useCopyRun = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: copyRun,
    onSuccess: (run) => {
      queryClient.invalidateQueries(getRunsQueryPrefix(solutionId));
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
      queryClient.invalidateQueries({ queryKey: ["influencingFactor"] });
      navigate(getRunDetailPath(solutionId, run.runConfigId));
    },
    onError: () => {
      toast.error(t("An error occurred while copying. Please try again."));
    },
  });
};

async function deleteRun(runConfigId: string, force = false) {
  return api.delete(`${RUNS_API}/${runConfigId}${force ? "?force=true" : ""}`);
}

export const useDeleteRun = (
  solutionId: string,
  onConfirmation?: (run: Run, entries: ModelRunDependency[]) => void,
  redirect = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({ run, force = false }: { run: Run; force?: boolean }) =>
      deleteRun(run.runConfigId.toString(), force),
    onSuccess: (_, { run: { runConfigId } }) => {
      toast.success(t("Run deleted successfully."));
      queryClient.invalidateQueries(getRunsQueryPrefix(solutionId));
      queryClient.removeQueries(runQuery(runConfigId));
      if (redirect) {
        navigate(getRunsPath(solutionId));
      }
    },
    onError: (error, { run }) => {
      if (error instanceof AxiosError && error.response?.status === 422) {
        const response = RunDeletionErrorZod.parse(error.response.data);
        if (onConfirmation) {
          onConfirmation(run, response.values);
        }
      } else {
        toast.error(t("An error occurred while deleting. Please try again."));
      }
    },
  });
};

type ResultPreviewParams = {
  runResultId: number | null;
  measurementId: number | null;
  partitionId: number | null;
};

async function getResultPreview(
  runConfigId: string,
  params: ResultPreviewParams
): Promise<ResultPreview | null> {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key as keyof ResultPreviewParams]}`)
    .join("&");
  try {
    return ResultPreviewZod.parse(
      (
        await api.get(
          `${RUNS_API}/${runConfigId}/PostprocessingPreview?${query}`
        )
      ).data
    );
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 404) {
        return null;
      }
    }
    throw e;
  }
}

export const resultPreviewQuery = (
  runConfigId: string,
  params: ResultPreviewParams
) => ({
  queryKey: ["resultPreview", runConfigId, params],
  queryFn: () => getResultPreview(runConfigId, params),
  enabled:
    !!params.measurementId && !!params.partitionId && !!params.runResultId,
});

async function getRunUsedIdentifiers(
  solutionId: string | number
): Promise<RunUsedIdentifiers[]> {
  return RunUsedIdentifiersZod.array().parse(
    (
      await api.get(
        `/Prognos/Solutions/${solutionId}/RunConfigs/UsedIdentifiers`
      )
    ).data
  );
}

export const runUsedIdentifiersQuery = (solutionId: string) => ({
  queryKey: [...getRunsQueryPrefix(solutionId).queryKey, "usedIdentifiers"],
  queryFn: () => getRunUsedIdentifiers(solutionId),
});
