import React, { type JSX } from "react";

import classNames from "classnames";

export const HERO_ACTION_BTN_BASE =
  "rounded-full w-[40px] h-[40px] p-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-black transition-colors duration-300";
const PRIMARY_STYLE =
  "bg-blue-600 text-white hover:bg-blue-700 border border-transparent focus:ring-blue-500";
const WHITE_STYLE = "text-slate-400 hover:text-slate-500 hover:bg-gray-100";

export const HERO_ACTION_BTN_PRIMARY = `${HERO_ACTION_BTN_BASE} ${PRIMARY_STYLE}`;
export const HERO_ACTION_BTN_WHITE = `${HERO_ACTION_BTN_BASE} ${WHITE_STYLE}`;

type HeroActionButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: "primary" | "white";
};

export default function HeroActionButton(
  props: HeroActionButtonProps
): JSX.Element {
  const { className, children, variant = "white", ...rest } = props;

  return (
    <button
      {...rest}
      className={classNames(
        "inline-flex items-center ",
        HERO_ACTION_BTN_BASE,
        className,
        variant === "white" ? WHITE_STYLE : PRIMARY_STYLE
      )}
    >
      {children}
    </button>
  );
}
