import React, { type JSX } from "react";
import { Link } from "react-router";

import classNames from "classnames";

import { SuiteApp, SuiteAppProps, useSuiteApps } from "../apps";
import { XIMES_COLORS } from "../services/constants";

interface AppsProps {
  id: string;
  size?: "medium" | "large";
  className?: string;
  omitApps?: SuiteApp[];
  onAppClick?(app: SuiteAppProps): void;
}

export default function Apps(props: AppsProps): JSX.Element {
  const { id, size = "medium", className, omitApps = [], onAppClick } = props;

  const apps = useSuiteApps();

  return (
    <ul id={id} className={classNames("", className)}>
      {apps
        .filter((app) => !omitApps.includes(app.key))
        .map((app) => (
          <li key={app.link} id={`${id}-${app.key}-link`}>
            <AppIconLink
              app={app}
              size={size}
              onClick={onAppClick ? () => onAppClick(app) : undefined}
            />
          </li>
        ))}
    </ul>
  );
}

interface AppIconLinkProps {
  app: SuiteAppProps;
  size: "medium" | "large";
  onClick?(): void;
}

function AppIconLink(props: AppIconLinkProps): JSX.Element {
  const { app, size, onClick } = props;
  const { link, label, icon: Icon } = app;

  return (
    <Link
      to={link}
      className="no-underline text-black text-center space-y-2 group hover:text-gray-700"
      onClick={onClick}
    >
      <div
        className={classNames(
          "flex items-center justify-center group-hover:brightness-110 rounded-full",
          size === "medium" ? "w-16 h-16" : "",
          size === "large" ? "w-24 h-24" : ""
        )}
        style={{
          fontSize: size === "large" ? 48 : size === "medium" ? 32 : 24,
          backgroundColor: app.color ?? XIMES_COLORS.GRAY,
        }}
      >
        {!Icon && <>{label[0]}</>}
        {Icon && (
          <Icon
            className={classNames(
              size === "medium" ? "w-8 h-8" : "",
              size === "large" ? "w-12 h-12" : ""
            )}
            style={{ color: app.fontColor ?? XIMES_COLORS.BLACK }}
          />
        )}
      </div>
      <p
        className={classNames(
          "text-gray-600 group-hover:text-gray-700 group-hover:underline",
          "hyphens-auto",
          size === "medium" ? "text-xs w-16" : "",
          size === "large" ? "text-sm w-24" : ""
        )}
      >
        {label}
      </p>
    </Link>
  );
}
