import { DayOfWeek } from "../models/primitives";

// standard page size used in the paginated tables
export const DEFAULT_PAGE_SIZE = 15;

export const XIMES_COLORS = {
  RED: "#ed6333",
  YELLOW: "#f6c744",
  GREEN: "#b2cf45",
  BLUE: "#4da1e0",
  BLACK: "#1a1a1a",
  // TODO
  GRAY: "#d1d1d1",
  WHITE: "#FFF",
} as const;

export const OTHER_COLORS = {
  BLUE: "#0c59cf",
  GREEN: "#32cd32",
  RED: "#e60049",
  GRAY: "#7f7f7f",
} as const;

export const SERIES_COLORS = [
  XIMES_COLORS.BLUE,
  XIMES_COLORS.GREEN,
  XIMES_COLORS.YELLOW,
  XIMES_COLORS.RED,
  "#9b19f5",
  "#4c7a34",
  "#a8c6fa",
  "#dc0ab4",
  "#00bfa0",
  "#cce90e",
] as const;

export const DAY_COLORS: Record<DayOfWeek, string> = {
  Monday: XIMES_COLORS.RED,
  Tuesday: OTHER_COLORS.GRAY,
  Wednesday: XIMES_COLORS.GREEN,
  Thursday: XIMES_COLORS.YELLOW,
  Friday: XIMES_COLORS.BLUE,
  Saturday: "#FF99FF",
  Sunday: "#AE78D6",
} as const;

export const DAILY_PROFILE_LINE_WIDTH = 1.5;
export const TIME_SERIES_LINE_WIDTH = 1.5;
