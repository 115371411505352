import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

import classNames from "classnames";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

import ConditionalWrapper from "./ConditionalWrapper";

interface SwitchBannerProps {
  children: React.ReactNode;
  previousLink: string | null;
  nextLink: string | null;
  isLoading?: boolean;
  index?: number;
  count?: number;
  filterLink?: string;
  className?: string;
  paddings?: boolean;
}

export default function SwitchBanner(props: SwitchBannerProps): JSX.Element {
  const { children, previousLink, nextLink, filterLink } = props;
  const { isLoading, index, count, className, paddings } = props;

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={classNames(paddings ? "px-3" : "", className)}>
        <div className="animate-pulse bg-gray-200 w-full rounded-sm h-7 py-2 pl-3 pr-10"></div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "flex justify-between items-center flex-nowrap space-x-2",
        paddings ? "px-6" : "",
        className
      )}
    >
      <h3 className="grow">{children}</h3>
      <div className="flex items-center space-x-2 shrink-0">
        {!!index && !!count && (
          <p className="text-sm font-normal text-gray-900">
            {index} {t("of")} {count}
          </p>
        )}
        {!!filterLink && (
          <p className="text-sm font-normal">
            <Link to={filterLink} title={t("Back to filter")}>
              {t("Filtered")}
            </Link>
          </p>
        )}
        <div className="flex items-center space-x-1">
          <ConditionalWrapper
            condition={!!previousLink}
            wrapper={(children) => (
              <Link to={previousLink ?? ""}>{children}</Link>
            )}
          >
            <SwitchIconWrapper disabled={!previousLink}>
              <ChevronUpIcon
                title={previousLink ? t("Previous planning area") : undefined}
              />
            </SwitchIconWrapper>
          </ConditionalWrapper>
          <ConditionalWrapper
            condition={!!nextLink}
            wrapper={(children) => <Link to={nextLink ?? ""}>{children}</Link>}
          >
            <SwitchIconWrapper disabled={!nextLink}>
              <ChevronDownIcon
                title={nextLink ? t("Next planning area") : undefined}
              />
            </SwitchIconWrapper>
          </ConditionalWrapper>
        </div>
      </div>
    </div>
  );
}

interface IconWrapperProps {
  children: React.ReactNode;
  disabled?: boolean;
}

export const SwitchIconWrapper = (props: IconWrapperProps): JSX.Element => {
  return (
    <span
      className={`w-6 h-6 align-top flex items-center ${
        props.disabled ? "text-gray-400 cursor-default" : "text-gray-600"
      }`}
    >
      {props.children}
    </span>
  );
};
