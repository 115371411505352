import { z } from "zod";

import { TrashIcon } from "@heroicons/react/24/outline";

export const DateZod = z.preprocess(
  (arg) => (typeof arg === "string" ? arg.split("T")[0] : arg),
  z.string().date()
);
export const dateToDateString = <T extends Date | null | undefined>(
  date: T
): T extends Date ? string : T => {
  if (date instanceof Date) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return date.toISOString().split("T")[0] as any;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return date as any;
};

export const DateTimeZod = z
  .string()
  .datetime({ offset: true })
  .transform((val) => new Date(val));

export const firstLowerCase = <T extends z.ZodTypeAny>(scheme: T) => {
  return z.preprocess(
    (arg) =>
      typeof arg === "string" && arg.length
        ? arg[0].toLowerCase() + arg.slice(1)
        : arg,
    scheme
  );
};

export const infinities = ["-inf", "inf"] as const;
export const InfinityStrZod = z.enum(infinities);
export type InfinityStr = z.infer<typeof InfinityStrZod>;

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;
export const DayOfWeekZod = z.enum(daysOfWeek);
export type DayOfWeek = z.infer<typeof DayOfWeekZod>;

export const shortDaysOfWeek = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
] as const;
export const ShortDayOfWeekZod = z.enum(shortDaysOfWeek);
export type ShortDayOfWeek = z.infer<typeof ShortDayOfWeekZod>;

export const firstUpperCase = <T extends z.ZodTypeAny>(scheme: T) => {
  return z.preprocess(
    (arg) =>
      typeof arg === "string" && arg.length
        ? arg[0].toUpperCase() + arg.slice(1)
        : arg,
    scheme
  );
};

export const InvalidZod = z.literal("Invalid");
export type Invalid = z.infer<typeof InvalidZod>;

export const orInvalidWrapper = <T extends z.ZodTypeAny>(scheme: T) => {
  return scheme.or(z.literal("Invalid")).catch("Invalid");
};

// prettier-multiline-arrays-next-line-pattern: 6
export const sortDirections = ["asc", "desc"] as const;
export const SortDirectionZod = z.enum(sortDirections);
export const SortSettingZod = z.tuple([z.string(), SortDirectionZod]);
export type SortSetting = z.infer<typeof SortSettingZod>;

// prettier-multiline-arrays-next-line-pattern: 8
export const filterOperators = [
  "<",
  "<=",
  "=",
  "!=",
  ">=",
  ">",
  "=*",
  "!*",
  "^",
  "!^",
  "$",
  "!$",
  "∅",
  "*",
] as const;
export const FilterOperatorZod = z.enum(filterOperators);
const FilterValueZod = z.string().or(z.number());
export const FilterSettingZod = z.tuple([
  z.string(),
  FilterOperatorZod,
  FilterValueZod.or(FilterValueZod.array()).readonly(),
]);
export type FilterSetting = z.infer<typeof FilterSettingZod>;

export const snakeToCamel = (str: string): string =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const snakeToCamelObject = (
  obj: object | null,
  otherMappings?: { [key: string]: string }
) => {
  if (obj === null) {
    return null;
  }
  const obj2 = obj as { [key: string]: unknown };
  const result: { [key: string]: unknown } = {};

  for (const key of Object.keys(obj2)) {
    let newKey = snakeToCamel(key);
    if (otherMappings?.[key]) {
      newKey = otherMappings[key];
    }
    result[newKey] = obj2[key.toString()];
  }

  return result;
};

export const convertSnakeToCamelValue = <T extends z.ZodTypeAny>(type: T) => {
  return z.preprocess(
    (arg: unknown) => (typeof arg === "string" ? snakeToCamel(arg) : arg),
    type
  );
};

export const convertSnakeToCamelObject = <T extends z.ZodTypeAny>(type: T) => {
  return z.preprocess(
    (arg: unknown) => (typeof arg === "object" ? snakeToCamelObject(arg) : arg),
    type
  );
};

export const convertToArray = <T extends z.ZodTypeAny>(type: T) => {
  return z.preprocess(
    (arg: unknown) =>
      Array.isArray(arg) || arg === null || typeof arg === "undefined"
        ? arg
        : [arg],
    type
  );
};

export const NumberOrInfinityZod = z.preprocess((arg: unknown) => {
  if (arg === "-inf") {
    return -Infinity;
  }
  if (arg === "inf") {
    return Infinity;
  }
  return arg;
}, z.number());

// prettier-multiline-arrays-next-line-pattern: 7
export const periodUnits = ["D", "W", "M", "Q", "Y"] as const;
export const PeriodUnitZod = z.enum(periodUnits);
export type PeriodUnit = z.infer<typeof PeriodUnitZod>;

export const PeriodZod = z.object({ length: z.number(), unit: PeriodUnitZod });
export type Period = z.infer<typeof PeriodZod>;

export function transformPeriodLength(period: string | Period): Period {
  if (typeof period !== "string") {
    return period;
  }

  const m = period.match(/^(\d+)([^\d]+)$/);
  if (m) {
    const length = parseInt(m[1]);
    const unit = PeriodUnitZod.safeParse(m[2]);

    if (unit.success) {
      return { length, unit: unit.data };
    }
  }

  return { length: 0, unit: "D" };
}

export type HeroIcon = typeof TrashIcon;
