import React, { type JSX } from "react";

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

export type AlertType =
  | "info"
  | "warning"
  | "error"
  | "success"
  | "fail"
  | "pass";

type IconSize = "sm" | "md" | "lg";

export interface AlertProps {
  children: React.ReactNode;
  type: AlertType;
  className?: string;
  iconSize?: IconSize;
}

export default function Alert(props: AlertProps): JSX.Element {
  const { children, type } = props;
  const { className = "", iconSize = "md" } = props;

  return (
    <p className={"text-sm flex items-center space-x-2 my-2 " + className}>
      <span className="shrink-0">
        <AlertIcon type={type} size={iconSize} />
      </span>
      <span>{children}</span>
    </p>
  );
}

type AlertIconProps = {
  type: AlertType;
  size: IconSize;
};

export function AlertIcon(props: AlertIconProps): JSX.Element {
  const { type, size } = props;
  const className = getIconClassName(size);

  switch (type) {
    case "info":
      return (
        <span className="text-blue-600">
          <InformationCircleIcon className={className} />
        </span>
      );
    case "warning":
      return (
        <span className="text-orange-600">
          <ExclamationTriangleIcon className={className} />
        </span>
      );
    case "error":
      return (
        <span className="text-red-600">
          <ExclamationCircleIcon className={className} />
        </span>
      );
    case "success":
      return (
        <span className="text-green-600">
          <CheckCircleIcon className={className} />
        </span>
      );
    case "fail":
      return (
        <span className="text-red-600">
          <XCircleIcon className={className} />
        </span>
      );
    case "pass":
      return (
        <span className="text-gray-500">
          <MinusCircleIcon className={className} />
        </span>
      );
  }
}

const getIconClassName = (size: IconSize): string => {
  switch (size) {
    case "sm":
      return "w-4 h-4";
    case "md":
      return "w-5 h-5";
    case "lg":
      return "w-6 h-6";
  }
};
