import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import classNames from "classnames";

import { FILTER_EL_STYLE } from ".";
import { TableDataType } from "../../../../components/datatable/types";

// prettier-multiline-arrays-next-line-pattern: 7
export const stringOperators = ["~", "=", "≠", "^", "$", "∅", "*"] as const;
type StringOperators = typeof stringOperators;
type StringOperator = StringOperators[number];

// prettier-multiline-arrays-next-line-pattern: 8
export const numericalOperators = [
  "=",
  "≠",
  "<",
  "≤",
  ">",
  "≥",
  "∅",
  "*",
] as const;
type NumericalOperators = typeof numericalOperators;
type NumericalOperator = NumericalOperators[number];

// prettier-multiline-arrays-next-line-pattern: 7
export const dateOperators = ["☍", "=", "≠", "<", "≤", ">", "≥"] as const;
type DateOperators = typeof dateOperators;
type DateOperator = DateOperators[number];

export type OperatorSet =
  | StringOperators
  | NumericalOperators
  | DateOperators
  | readonly [];
export type Operator = StringOperator | NumericalOperator | DateOperator;

export function getOperators(type: TableDataType): OperatorSet {
  switch (type) {
    case "string":
    case "user":
      return stringOperators;
    case "number":
      return numericalOperators;
    case "date":
    case "datetime":
      return dateOperators;
    default:
      return [];
  }
}

interface OperatorSelectProps<T extends Operator> {
  options: readonly T[];
  value: Operator;
  onChange(o: Operator): void;
}

const PADDINGS = "px-2";

export default function OperatorSelect<T extends Operator>(
  props: OperatorSelectProps<T>
): JSX.Element {
  const { options, value, onChange } = props;

  const titles = useOperatorTitles();

  return (
    <Listbox value={value} onChange={onChange}>
      <div
        className="relative inline-block h-[30px]"
        onClick={(e) => e.stopPropagation()}
      >
        <ListboxButton
          className={classNames(
            "relative cursor-pointer sm:text-sm py-1 px-2 w-7 text-center",
            FILTER_EL_STYLE,
            "font-bold",
            PADDINGS
          )}
          title={titles[value]}
        >
          <span className="block truncate">{value}</span>
        </ListboxButton>
        <ListboxOptions className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-sm bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
          {options.map((option) => (
            <ListboxOption
              key={option}
              className={({ focus, selected }) =>
                classNames(
                  focus
                    ? "text-white bg-blue-600"
                    : selected
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-900",
                  "relative cursor-pointer select-none",
                  PADDINGS,
                  "p-1 px-2.5"
                )
              }
              value={option}
            >
              {({ focus, selected }) => (
                <span
                  id={`operatorSelect-option-${option}`}
                  className={classNames("block truncate")}
                >
                  <span className="inline-block font-bold w-[10px] text-center">
                    {option}
                  </span>
                  <span
                    className={classNames(
                      "ml-3",
                      !focus && !selected ? "text-gray-500 font-light" : ""
                    )}
                  >
                    {titles[option]}
                  </span>
                </span>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

function useOperatorTitles(): { [key in Operator]: string } {
  const { t } = useTranslation();

  return {
    "<": t("less than"),
    "≤": t("less than or equal"),
    "=": t("equal"),
    "≠": t("not equal"),
    "≥": t("greater than or equal"),
    ">": t("greater than"),
    "^": t("starts with"),
    $: t("ends with"),
    "~": t("contains"),
    "∅": t("empty"),
    "*": t("non-empty"),
    "☍": t("in time range"),
  };
}
