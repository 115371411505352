import React from "react";
import { RouteObject } from "react-router";

import { tasksRoute } from "../../../routes/tasks";
import { userSettingsRoute } from "../../../routes/userSettings";
import lazyWithRetry from "../../../services/lazyWithRetry";
import AnalyzerLayout from "../components/layouts/AnalyzerLayout";
import { analysisRoute } from "./analysis";
import { ANALYZER_PATH } from "./analyzer";

const Analyses = lazyWithRetry(() => import("../pages/Analyses/Analyses"));

export const analyzerRoute: RouteObject = {
  path: ANALYZER_PATH,
  element: <AnalyzerLayout />,
  children: [
    { index: true, element: <Analyses /> },
    analysisRoute,
    userSettingsRoute,
    tasksRoute,
  ],
};
