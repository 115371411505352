import React from "react";
import { RouteObject } from "react-router";

import { tasksRoute } from "../../../routes/tasks";
import { userSettingsRoute } from "../../../routes/userSettings";
import lazyWithRetry from "../../../services/lazyWithRetry";
import DataStoreLayout from "../components/layouts/DataStoreLayout";
import { businessHoursRoute } from "./businessHours";
import { DATA_STORE_PATH } from "./dataStore";
import { importRoute } from "./import";
import { reviewRoute } from "./review";

const Dashboard = lazyWithRetry(() => import("../pages/Dashboard/Dashboard"));

export const dataStoreRoute: RouteObject = {
  path: DATA_STORE_PATH,
  element: <DataStoreLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    importRoute,
    reviewRoute,
    businessHoursRoute,
    userSettingsRoute,
    tasksRoute,
  ],
};
