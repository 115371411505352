import React from "react";

import ButtonMultiGroup, { ButtonMultiGroupProps } from "./ButtonMultiGroup";

interface ButtonGroupProps<T> extends Omit<ButtonMultiGroupProps<T>, "active"> {
  active?: T;
  error?: string;
}

export default function ButtonGroup<T extends string | number | boolean>(
  props: ButtonGroupProps<T>
) {
  const { active, ...rest } = props;
  return (
    <ButtonMultiGroup
      {...rest}
      active={active || active === false ? [active] : undefined}
    />
  );
}
