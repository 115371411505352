import React from "react";
import { Link, useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";

import Card from "../../../../components/Card";
import Portal from "../../../../components/Portal";

export interface TabDefinition<T = string> {
  key: T;
  label: string;
  href: string;
  disabled?: boolean;
  disabledTooltip?: string;
  count?: number;
}

interface ButtonTabsProps {
  tabs: TabDefinition[];
  selected: string;
}

export default function ButtonTabs(props: ButtonTabsProps) {
  const { tabs, selected } = props;

  const navigate = useNavigate();

  return (
    <div>
      <Card className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={
            (tabs.find((tab) => tab.key === selected) ?? tabs[0]).label
          }
          onChange={(e) => navigate(e.target.value)}
        >
          {tabs
            .filter((tab) => !tab.disabled)
            .map((tab) => (
              <option key={tab.key} value={tab.href}>
                {tab.label}
              </option>
            ))}
        </select>
      </Card>
      <div className="hidden sm:block px-3">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <div
              key={tab.key}
              className={classNames(
                "px-3 py-2 font-medium text-sm rounded-md",
                tab.key === selected ? "bg-gray-200" : ""
              )}
            >
              {!tab.disabled ? (
                <Link
                  id={`tabLink-${tab.key}`}
                  to={tab.href}
                  aria-current={tab.key === selected ? "page" : undefined}
                  className={classNames(
                    "no-underline",
                    tab.key === selected
                      ? "text-gray-800"
                      : "text-gray-600 hover:text-gray-800"
                  )}
                >
                  {tab.label}
                </Link>
              ) : (
                <span
                  className="cursor-default text-gray-500"
                  data-tooltip-id={`buttontabs-${tab.key}-tooltip`}
                >
                  {tab.label}
                  {tab.disabledTooltip && (
                    <Portal>
                      <Tooltip
                        id={`buttontabs-${tab.key}-tooltip`}
                        content={tab.disabledTooltip}
                        place="bottom"
                        variant="light"
                        className="text-xs! max-w-xs! border! border-gray-100!"
                      />
                    </Portal>
                  )}
                </span>
              )}
              {typeof tab.count === "number" && (
                <span
                  className={classNames(
                    "ml-2 py-0.5 px-2 rounded-lg text-xs font-medium",
                    tab.key === selected
                      ? "bg-white text-gray-900"
                      : "bg-gray-200 text-gray-900",
                    tab.disabled ? "cursor-default" : ""
                  )}
                >
                  {tab.count}
                </span>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
