import { z } from "zod";

const messageTypes = ["TaskUpdate", "AggregatesAreDirty"] as const;
const MessageTypeZod = z.enum(messageTypes);
export type MessageType = z.infer<typeof MessageTypeZod>;

export const MessageZod = z.object({
  eventName: MessageTypeZod,
  eventArgs: z.any(),
});

export type Message = z.infer<typeof MessageZod>;
